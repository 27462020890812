<template>
  <div class="codigosstreaming">
    <tabla titulo="Códigos Streaming" 
          :headers = "headers"
          :arreglo = "CodigosStreamingGetter.items"
            />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import tabla from '@/components/codigosstreaming/';

export default {
  name: 'codigosstreaming',

   components: {
    tabla
  },
  
  created(){
    this.ConsultarCodigosStreaming()
  },

  data: () =>{
    return{
    headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'PLATAFORMA', value: 'nombre_plataforma' },
        { text: 'USUARIO', value: 'usuario' },
        { text: 'CONTRASEÑA', value: 'pass' },
        { text: 'FECHA COMPRA', value: 'fecha_compra_format' },
        { text: 'Días', value: 'dias' },
        { text: 'TIPO', value: 'tipo' },
        { text: 'USO', value: 'uso' },
        { text: 'ACCIONES', value: 'actions', sortable: false },
      ],

    }
  },

  methods: {
    ...mapActions(['ConsultarCodigosStreaming']),

  },

  computed:{
      ...mapGetters(['CodigosStreamingGetter'])
  }
}
</script>
