<template>
    <div>
      <v-form
         v-on:submit.prevent="DialogAceptar = true"
          ref="form"
          lazy-validation
      >
        <v-card>
        <v-card-title>
          <span class="headline">{{Accion}}</span>
          <v-spacer></v-spacer>
          <span v-if="Item.id != 0" class="headline">ID: {{Item.id}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
             <v-row>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                    v-model="Item.plataforma"
                    :items="PlataformasStreamingGetter.items"
                    item-text="nombre"
                    item-value="id"
                    menu-props="auto"
                    label="Plataforma Streaming"
                    persistent-hint
                    ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.usuario"
                  label="Usuario *"
                  hint="Nombre de usuario de la plataforma"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.pass"
                  label="Contraseña *"
                  hint="Contraseña de la cuenta"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.pin"
                  label="Pin *"
                  hint="Pin de la cuenta"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

             <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  type="date"
                  v-model="Item.fecha_compra"
                  label="Fecha de Compra *"
                  hint="Fecha en que se compro la cuenta"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.dias"
                  label="Duración *"
                  hint="Tiempo de duración de la cuenta en días"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.tipo"
                  label="Tipo *"
                  hint="Tipo de Cuenta, compartida o full"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

            </v-row>
          </v-container>

          <small>* indica campo requerido</small>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="Ocultar()">Cerrar</v-btn>
          <v-btn color="primary" type="submit">Guardar</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>

     <v-dialog v-model="DialogAceptar" persistent max-width="380">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{Accion}}
        </v-card-title>

        <v-card-text class="mt-4">
          <h2>¿Estas seguro de guardar?</h2>
        </v-card-text>
        
        <v-divider></v-divider>

        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="DialogAceptar = false">Cancelar</v-btn>
          <v-btn color="primary" @click="Guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </div>
</template>

<script>
import { mapState, mapGetters, mapActions} from 'vuex'

export default ({

  props:['Accion','Item','NuevoActivo'],

  watch:{
      Alerta(value){
        if(value.error == false){
          this.Ocultar()
        }
      }
  },

  created (){
    this.ObtenerPlataformasStreaming()
  },

  data: () => ({
    var: null,
    DialogAceptar: false,

    ItemEnviar: {
            id: null,
            plataforma: null,
            usuario: null,
            pass: null,
            pin: null,
            fecha_compra: null,
            dias: null,
            tipo: null,
          },
  }),

  methods: {
     ...mapActions(['NuevoCodigoStreaming', 'ConsultarCodigosStreaming', 'ObtenerPlataformasStreaming']),

    Guardar (){

      this.ItemEnviar.id = this.Item.id
      this.ItemEnviar.plataforma = this.Item.plataforma
      this.ItemEnviar.usuario = this.Item.usuario
      this.ItemEnviar.pass = this.Item.pass
      this.ItemEnviar.pin = this.Item.pin
      this.ItemEnviar.fecha_compra = this.Item.fecha_compra
      this.ItemEnviar.dias = this.Item.dias
      this.ItemEnviar.tipo = this.Item.tipo

      this.NuevoCodigoStreaming(this.ItemEnviar)
      setTimeout( () => {this.ConsultarCodigosStreaming()}, 500)
      this.DialogAceptar = false
    },

    Ocultar(){
        this.$emit('NuevoActivo', false)
    }
  },

  computed:{
    ...mapState(['Alerta']),
    ...mapGetters(['SINO','PlataformasStreamingGetter'])
  }


})
</script>